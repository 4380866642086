@import url('https://fonts.googleapis.com/css?family=Rajdhani&display=swap');

html,
.gameview-container {
  background-color: #5c0a63;
  background-image: url('/img/background.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

html, body, #root {
  height: 100%;
  font-family: 'Rajdhani', sans-serif;
  color: #fff;
}

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.centered-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  text-align: center;
}

.big-header {
  font-size: 96px;
  margin: 10px;
}

.sub-header {
  font-size: 48px;
  font-weight: 100;
  margin: 10px;
}

.player-count {
  font-size: 20px;
  margin: 50px;
}

.index-view .big-header {
  margin-top: auto;
}

.index-view .player-count {
  margin-top: auto;
  padding-top: 50px;
}

@media screen and (max-width: 768px) {
  .big-header {
    font-size: 60px;
  }
  .sub-header {
    font-size: 32px;
  }
}

/* GameView */

.gameview-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.gameview-container:before,
.gameview-container:after {
  content: "";
  flex-grow: 1;
}
.gameview-layout {
  padding: 30px 0;
  max-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.game-board {
  width: calc(148vh - 88px);
  max-width: 1200px;
  font-size: 0;
}
.game-board canvas {
  max-width: 100%;
  max-height: 100%;
}
.player-container {
  padding: 0 15px;
  /* as flex container */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* as flex item */
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 310px;
}
.player-container-secondary {
  align-self: flex-end;
  align-items: flex-end;
  text-align: right;
}
.player-container-primary {
  text-align: left;
}
.player-name {
  width: 280px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 34px;
}
.player-name-gone {
  opacity: .3;
}
.player-win-count {
  margin: 0 0.3em 0 0;
}
.color-options {
  height: 68px;
}
.color-option,
.player-color-option {
  display: inline-block;
  width: 48px;
  height: 48px;
  border: 0;
  padding: 0;
  margin: 10px 0 10px 10px;
  background-color: rgba(212, 86, 255, 0.05);
}
.color-option:first-child,
.player-color-option:first-child {
  margin-left: 0;
}
.color-option {
  cursor: pointer;
}
.color-option[disabled],
.player-color-option[disabled] {
  background-image: url('/img/icon-lock.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-blend-mode: multiply;
  opacity: .3;
  cursor: default;
}
.scoreboard {
  margin-top: auto;
}
.score-progress-bar {
  width: 280px;
  height: 32px;
  margin-bottom: 10px;
  color: #fff;
  background-color: rgba(0, 127, 10, 0.15);
  background-image: linear-gradient(currentColor, currentColor);
  background-repeat: no-repeat;
  background-size: 0 100%;
}

@media screen and (max-width: 1200px) {
  .gameview-container {
    display: block;
  }
  .gameview-layout {
    flex-direction: column;
    height: 100%;
    padding: 0 15px;
  }
  .game-board {
    width: auto;
    height: calc(148vw - 44px);
    min-height: 0;
    text-align: center;
  }
  .player-container {
    flex-basis: auto;
    width: 100%;
    padding: 5px 0;
  }
  .player-container-secondary {
    align-self: start;
    align-items: flex-start;
    text-align: left;
    justify-content: flex-end;
  }
  .player-container-primary {
    align-self: flex-end;
    text-align: right;
  }
  .color-options {
    margin: 0 auto 30px auto;
    order: 3;
  }
  .player-name {
    width: auto;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    font-size: 18px;
  }
  .player-container-secondary .player-name {
    justify-content: flex-end;
  }
  .player-container-primary .player-win-count {
    margin: 0 0 0 0.3em;
  }
  .player-container-secondary .player-win-count {
    margin: 0 0.3em 0 0;
  }
  .player-color-options {
    height: 16px;
    margin: 0 15px 3px 15px;
  }
  .player-color-option {
    width: 16px;
    height: 16px;
    margin: 0 0 0 5px;
  }
  .color-option {
    background-color: rgba(0, 127, 10, 0.1);
  }
  .scoreboard {
    margin-top: 5px;
  }
  .score-progress-bar {
    width: 100%;
    height: 15px;
    margin-bottom: 5px;
  }
}

.waiting-modal,
.guide-modal,
.game-over-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(35, 0, 47, 0.5);
  font-size: 34px;
  text-align: center;
  z-index: 1;
}
.guide-modal + .gameview-container,
.waiting-modal + .gameview-container {
  filter: blur(7px);
}
.game-over-modal + .gameview-container {
  filter: blur(3px);
}
.guide-modal {
  background: rgba(35, 0, 47, 0.9);
}
.guide-text {
  max-width: 30em;
  padding: 0 0.7em;
  text-align: left;
}
.guide-text p {
  margin: 0.7em 0;
}
.share-link {
  color: #c298ff;
  text-shadow: 1px 1px 4px black;
  font-size: 60px;
  text-decoration: none;
}
@media screen and (max-width: 768px) {
  .share-link {
    font-size: 36px;
  }
  .guide-text p {
    font-size: 28px;
  }
}
